import { createApi } from '@reduxjs/toolkit/query/react'

import { LIMIT_EXPORT, PromoterStatus } from '@/config/constants'
import { ADMIN_PROMOTER, ADMIN_PROMOTER_DETAIL, ADMIN_PROMOTER_RESEND, ADMIN_PROMOTER_STATUS } from '@/config/endpoints'
import { BaseDetailRequest, BaseErrorResponse } from '@/types/common'
import {
  PromoterBulkCreateResponse,
  PromoterBulkCreateResponseData,
  PromoterChangeStatusRequest,
  PromoterCreateRequest,
  PromoterCreateRequestData,
  PromoterDetailResponse,
  PromoterListRequest,
  PromoterListResponse,
  PromoterUpdateRequest,
} from '@/types/promoter'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'promoter',
  tagTypes: ['Promoter'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    createPromoter: builder.mutation<void, PromoterCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTER),
        method: 'POST',
      }),
      invalidatesTags: ['Promoter'],
    }),
    getListPromoter: builder.query<PromoterListResponse, PromoterListRequest>({
      query: (request) => {
        const newRequest = { ...request }

        if (request.query && request.query.filters && !request.query.filters.promoterStatusId) {
          newRequest.query = {
            ...request.query,
            filters: {
              ...request.query.filters,
              promoterStatusId: [String(PromoterStatus.ACTIVE), String(PromoterStatus.UNCONFIRMED)].join(','),
            },
          }
        }

        return {
          ...composeRequest(newRequest, ADMIN_PROMOTER),
          method: 'GET',
        }
      },
      providesTags: ['Promoter'],
    }),
    getListArchivePromoter: builder.query<PromoterListResponse, PromoterListRequest>({
      query: (request) => {
        const newRequest = { ...request }

        if (request.query && request.query.filters && !request.query.filters.promoterStatusId) {
          newRequest.query = {
            ...request.query,
            filters: {
              ...request.query.filters,
              promoterStatusId: String(PromoterStatus.INACTIVE),
            },
          }
        }

        return {
          ...composeRequest(newRequest, ADMIN_PROMOTER),
          method: 'GET',
        }
      },
      providesTags: ['Promoter'],
    }),
    getDetailPromoter: builder.query<PromoterDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTER_DETAIL),
        method: 'GET',
      }),
    }),
    updatePromoter: builder.mutation<void, PromoterUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTER_DETAIL),
        method: 'PUT',
      }),
      invalidatesTags: ['Promoter'],
    }),
    changeStatusPromoter: builder.mutation<void, PromoterChangeStatusRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTER_STATUS),
        method: 'PATCH',
      }),
      invalidatesTags: ['Promoter'],
    }),
    resendEmailPromoter: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTER_RESEND),
        method: 'POST',
      }),
    }),
    bulkCreatePromoter: builder.mutation<PromoterBulkCreateResponse, PromoterCreateRequest[]>({
      queryFn: async (items, api, extraOptions, baseQuery) => {
        const responses: PromoterBulkCreateResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        for (const item of items) {
          const idx = items.indexOf(item)
          const response: PromoterBulkCreateResponseData = {} as PromoterBulkCreateResponseData

          const { error } = await baseQuery({
            ...composeRequest(item, ADMIN_PROMOTER),
            method: 'POST',
          })

          if (error) {
            response.row = idx + 1
            response.item = item.data as PromoterCreateRequestData
            response.error = error.data as BaseErrorResponse

            responses.data.push(response)
          }
        }

        if (responses.data.length > 0) {
          responses.success = false
        }

        return { data: responses }
      },
    }),
    exportListPromoter: builder.query<PromoterListResponse, PromoterListRequest>({
      queryFn: async (request, _api, extraOptions, baseQuery) => {
        const data: PromoterListResponse = {
          success: true,
          code: 'OK',
          message: '',
          data: {
            items: [],
            metadata: {
              skip: '0',
              limit: '0',
              sortBy: 'latest',
              count: '0',
            },
          },
        }

        const newRequest: PromoterListRequest = { ...request }

        if (request.query && request.query.filters && !request.query.filters.promoterStatusId) {
          newRequest.query = {
            ...request.query,
            filters: {
              ...request.query.filters,
              promoterStatusId: [String(PromoterStatus.ACTIVE), String(PromoterStatus.UNCONFIRMED)].join(','),
            },
          }
        }

        const limit = LIMIT_EXPORT
        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit,
                    skip,
                  },
                },
                ADMIN_PROMOTER
              ),
              method: 'GET',
            })

            const {
              data: { items },
            } = response.data as PromoterListResponse

            items.forEach((item) => {
              data.data.items.push(item)
            })

            skip += limit
            hasMore = items.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useCreatePromoterMutation,
  useLazyGetListPromoterQuery,
  useLazyGetListArchivePromoterQuery,
  useLazyGetDetailPromoterQuery,
  useUpdatePromoterMutation,
  useChangeStatusPromoterMutation,
  useResendEmailPromoterMutation,
  useBulkCreatePromoterMutation,
  useLazyExportListPromoterQuery,
  util: promoterUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
