import { createApi } from '@reduxjs/toolkit/query/react'

import { LIMIT_EXPORT, PromotionActiveStatus } from '@/config/constants'
import {
  ADMIN_PROMOTION,
  ADMIN_PROMOTION_DELETE,
  ADMIN_PROMOTION_DETAIL,
  ADMIN_PROMOTION_HISTORY,
  ADMIN_PROMOTION_STATUS,
} from '@/config/endpoints'
import { BaseDetailRequest } from '@/types/common'
import {
  PromotionChangeStatusRequest,
  PromotionCreateRequest,
  PromotionDetailApiResponse,
  PromotionDetailResponse,
  PromotionListHistoryRequest,
  PromotionListHistoryResponse,
  PromotionListRequest,
  PromotionListResponse,
  PromotionUpdateRequest,
} from '@/types/promotion'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'promotion',
  tagTypes: ['Promotion'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    createPromotion: builder.mutation<void, PromotionCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION),
        method: 'POST',
      }),
      invalidatesTags: ['Promotion'],
    }),
    getDetailPromotion: builder.query<PromotionDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_DETAIL),
        method: 'GET',
      }),
    }),
    updatePromotion: builder.mutation<void, PromotionUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_DETAIL),
        method: 'PUT',
      }),
      invalidatesTags: ['Promotion'],
    }),
    // List Promotion
    getListPromotion: builder.query<PromotionListResponse, PromotionListRequest>({
      query: (request) => {
        const newRequest = { ...request }

        if (request.query && request.query.filters && !request.query.filters.statusId) {
          newRequest.query = {
            ...request.query,
            filters: {
              ...request.query.filters,
              statusId: [String(PromotionActiveStatus.ACTIVE), String(PromotionActiveStatus.INACTIVE)].join(','),
            },
          }
        }

        return {
          ...composeRequest(newRequest, ADMIN_PROMOTION),
          method: 'GET',
        }
      },
      providesTags: ['Promotion'],
    }),
    getDetailApiPromotion: builder.query<PromotionDetailApiResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_DETAIL),
        method: 'GET',
      }),
    }),
    getListHistoryPromotion: builder.query<PromotionListHistoryResponse, PromotionListHistoryRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_HISTORY),
        method: 'GET',
      }),
      providesTags: [],
    }),
    changeStatusPromotion: builder.mutation<void, PromotionChangeStatusRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_STATUS),
        method: 'PUT',
      }),
      invalidatesTags: ['Promotion'],
    }),
    deletePromotion: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_DELETE),
        method: 'DELETE',
      }),
      invalidatesTags: ['Promotion'],
    }),
    exportListPromotion: builder.query<PromotionListResponse, PromotionListRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: PromotionListResponse = {
          success: true,
          code: 'OK',
          message: '',
          data: {
            items: [],
            metadata: {
              skip: '0',
              limit: '0',
              sortBy: 'latest',
              count: '0',
            },
          },
        }

        const newRequest: PromotionListRequest = { ...request }

        if (newRequest.query && newRequest.query.filters && newRequest.query.filters.isActive) {
          newRequest.query.filters.isActive = String(PromotionActiveStatus.ACTIVE)
        }

        const limit = LIMIT_EXPORT
        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const promotions = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit,
                    skip,
                  },
                },
                ADMIN_PROMOTION
              ),
              method: 'GET',
            })

            const {
              data: { items: promotionsItems },
            } = promotions.data as PromotionListResponse

            promotionsItems.forEach((item) => {
              data.data.items.push(item)
            })

            skip += limit
            hasMore = promotionsItems.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
    exportListHistoryPromotion: builder.query<PromotionListHistoryResponse, PromotionListHistoryRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: PromotionListHistoryResponse = {
          success: true,
          code: 'OK',
          message: '',
          data: {
            items: [],
            metadata: {
              skip: '0',
              limit: '0',
              sortBy: 'latest',
              count: '0',
            },
          },
        }

        const limit = LIMIT_EXPORT
        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const promotions = await baseQuery({
              ...composeRequest(
                {
                  ...request,
                  query: {
                    ...request.query,
                    limit,
                    skip,
                  },
                },
                ADMIN_PROMOTION_HISTORY
              ),
              method: 'GET',
            })

            const {
              data: { items },
            } = promotions.data as PromotionListHistoryResponse

            items.forEach((item) => {
              data.data.items.push(item)
            })

            skip += limit
            hasMore = items.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetListPromotionQuery,
  useCreatePromotionMutation,
  useLazyGetDetailPromotionQuery,
  useUpdatePromotionMutation,
  useLazyGetListHistoryPromotionQuery,
  useChangeStatusPromotionMutation,
  useDeletePromotionMutation,
  useLazyExportListPromotionQuery,
  useLazyGetDetailApiPromotionQuery,
  useLazyExportListHistoryPromotionQuery,
  util: promotionUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
