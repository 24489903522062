// Error
export const ERROR_INTERNAL = '500'
export const ERROR_RESOURCE_NOT_FOUND = 'E_COMM_1'
export const ERROR_RESOURCE_STALE = 'E_COMM_2'
export const ERROR_AUTH_EXPIRED = 'E_AUTH_1'
export const ERROR_AUTH_INACTIVE = 'E_AUTH_2'
export const ERROR_EMAIL_ALREADY_EXISTS = 'E_ADMN_1'
export const ERROR_PHONE_ALREADY_EXISTS = 'E_ADMN_2'
export const ERROR_STORE_ID_ALREADY_EXISTS = 'E_STORE_1'
export const ERROR_STORE_EMAIL_ALREADY_EXISTS = 'E_STORE_2'
export const ERROR_STORE_PHONE_ALREADY_EXISTS = 'E_STORE_3'
export const ERROR_PROMOTER_ID_ALREADY_EXISTS = 'E_PROM_1'
export const ERROR_PROMOTER_EMAIL_ALREADY_EXISTS = 'E_PROM_2'
export const ERROR_PROMOTER_PHONE_ALREADY_EXISTS = 'E_PROM_3'
export const ERROR_DEVICE_NAME_ALREADY_EXISTS = 'E_DEVC_1'
export const ERROR_DEVICE_STATUS_INVALID = 'E_DEVI_1'
export const ERROR_DEVICE_PRE_ORDER_RELEASE_DATE_INVALID = 'E_DEVI_5'
export const ERROR_BRAND_ALREADY_EXISTS = 'E_BRND_1'
export const ERROR_OLD_DEVICE_ALREADY_EXISTS = 'E_OLD_DEV_1'
export const ERROR_DEVICE_INVALID = 'E_VALD_1'
export const ERROR_PROMOTION_VALUE_ALREADY_EXISTS = 'E_PROG_4'
export const ERROR_PRIORITY_VALUE_ALREADY_EXISTS = 'E_PROG_5'
export const ERROR_PROMOTION_START_DATE_SHOULD_BE_LESS_THAN_END_DATE = 'E_COMM_5'
export const ERROR_PROMOTION_DATE_SHOULD_BE_AFTER_TODAY = 'E_CAMP_1'
export const ERROR_PROMOTION_DAILY_QUOTA_SHOULD_BE_LESS_THAN_QUOTA = 'E_PROG_10'
export const ERROR_PROMOTION_PRIORITY_MAX_VALUE = 'E_VALD_1'

// limit
export const LIMIT_EXPORT = 300

export const MAX_FILE_SIZE_BANNER = 5 * 1024 * 1024

export enum SortBy {
  Latest = 'latest',
  Earliest = 'earliest',
}

export enum Status {
  UNKNOWN_CONTROL_STATUS = 0,
  ACTIVE = 1,
  INACTIVE = 2,
  PENDING = 3,
  LOCKED = 4,
  DRAFT = 5,
}

export enum StoreStatus {
  ACTIVE = 1,
  CLOSED = 2,
  INACTIVE = 3,
  LOW_ACTIVE = 4,
}

export enum StorePICStatus {
  UNCONFIRMED = 1,
  REGISTERED,
  DRAFT,
}

export enum PromoterStatus {
  ACTIVE = 1,
  INACTIVE,
  UNCONFIRMED,
}

export enum ExclusivityFlag {
  MULTI_BRAND = 1,
  SAMSUNG_EXCLUSIVE,
}

export enum ChannelType {
  MODERN_CHANNEL = 1,
  TRADITIONAL,
  ONLINE,
  B2B,
  DIRECT_SALES,
  OTHERS,
}

export enum Brand {
  SAMSUNG = 1,
  APPLE = 2,
  HUAWEI = 3,
  XIAOMI = 5,
  OPPO = 6,
  OTHER = 7,
}

export enum BrandShop {
  SAMSUNG_EXPERIENCE_STORE = 1,
  SAMSUNG_EXPERIENCE_STORE_PARTNER,
  SHOP_IN_SHOP,
  NORMAL_STORE,
  WINNING_ZONE,
  SAMSUNG_PARTNER_PLAZA,
  B2B,
  EXCLUSIVE_COMPETITOR_SHOP,
  SAMSUNG_BRAND_SHOP,
  SAMSUNG_SUPER_STORE,
  TEMPORARY_EVENT,
  VIRTUAL,
  WAREHOUSE,
}

export enum Partner {
  UNKNOWN,
  LAKU6,
  COMPASIA,
  ENB,
  LOCAL_PARTNER,
}

export enum PartnerType {
  ALL,
  OFFICIAL,
  LOCAL,
}

export enum PartnerStatus {
  UNKNOWN,
  ACTIVE,
  INACTIVE,
}

export enum DeviceIdentifierType {
  UNKNOWN = 0,
  IMEI,
  SERIAL_NUMBER,
}

export enum DeviceConnectivity {
  WIFI_CELLULAR = 1,
  WIFI_ONLY,
  NO_CONNECTIVITY,
}

export enum DeviceStatus {
  ACTIVE = 1,
  INACTIVE,
}

export enum DeviceType {
  UNKNOWN,
  ALL,
  BRAND,
  MODEL,
}

export enum NewDeviceType {
  UNKNOWN,
  ALL,
  MODEL,
}

export enum DeviceCategory {
  UNKNOWN,
  SMARTPHONE_TABLET,
  SMARTWATCH,
  EARBUD,
}

export enum TradeInStatus {
  ACTIVE = 1,
  SUCCESS,
  VOID,
  CANCELED,
  EXPIRED,
  CONFIRM,
  WAITING_PO,
}

export enum TradeInPickupStatus {
  WAITING = 1,
  DONE,
  CANCELED,
}

export enum TradeInPaymentStatus {
  WAITING = 1,
  DONE,
  CANCELED,
}

export enum TradeInGrade {
  A = 'A',
  B = 'B',
  B2 = 'B2',
  C = 'C',
  D = 'D',
}

export enum TradeInPhoto {
  UNKNOWN,
  FRONT,
  BACK,
  LEFT,
  RIGHT,
  TOP,
  BOTTOM,
  OTHERS,
  ENB_FRONT,
  ENB_OTHER,
}

export enum VendorContributionStatus {
  ACTIVE = 1,
  INACTIVE,
}

export enum NumberDevice {
  NewDevice = 1,
  OldDevice,
}

export enum GradeResult {
  A = 1,
  B,
  B2,
  C,
  D,
}

export enum PromoterTransaction {
  Transact = 1,
  NoTransaction,
}

export enum PromoterLoginActivity {
  Zero = 1,
  Low,
  Medium,
  High,
}

export enum PromotionStatus {
  SCHEDULED = 1,
  ONGOING,
  FINISHED,
  FREEZE,
}

export enum PromotionActiveStatus {
  ACTIVE = 1,
  INACTIVE,
}

export enum PromotionOldDeviceType {
  CATEGORY = 1,
  BRAND,
  MODEL,
}

export enum PromotionNewDeviceType {
  CATEGORY = 1,
  MODEL,
}

export enum PromotionStoreType {
  ALL = 1,
  STORE,
  CHANNEL_TYPE,
  BRAND_SHOP,
  ACCOUNT,
}

export enum ShopType {
  UNKNOWN,
  ALL,
  STORE,
  CHANNEL,
  BRAND,
  ACCOUNT,
}

export enum OrderType {
  REGULAR = 1,
  PRE_ORDER,
}

export enum LocationLevel {
  PROVINCE = 1,
  CITY = 2,
  AREA = 3,
}

export enum TabDetailDevices {
  FIRST_ASSESMENT = 1,
  RE_ASSESMENT,
}

export enum TradeInValidationStatus {
  RECHECK = 1,
  WAITING_VALIDATION,
  UPDATE_REQUIRED,
  REJECTED,
  VALIDATED,
}

export enum TradeInAssessmentStatus {
  DRAFT = 1,
  SUBMIT,
  SUCCESS,
  CANCELED,
  EXPIRED,
  FAILED,
  PENDING_VERIFICATION,
}

export enum AssetType {
  AVATAR = 1,
  VERIFICATION_PHOTO,
  ASSET_TYPE_TRANSACTION,
  IDENTITY_CARD,
  DEVICE_DATA,
  PRE_ORDER_QR_CODE,
  BANNER,
}

export enum SortByPromotionBanner {
  Latest = 'sort-desc',
  Earliest = 'sort-asc',
}

export const SortByPromotionBannerItems = [
  {
    id: 'sort-desc',
    label: 'Newest',
  },
  {
    id: 'sort-asc',
    label: 'Oldest',
  },
]

export enum PromotionBannerStatus {
  SCHEDULED = 'Scheduled',
  ONGOING = 'On going',
  FINISHED = 'Finished',
}

export enum PromotionBannerActiveStatus {
  ACTIVE = 1,
  INACTIVE,
}

export enum PromotionBannerPreference {
  FINISHED = 1,
  ONGOING,
}

export enum PromotionBannerSequence {
  DOWN = 1,
  UP,
}

export enum PromotionCampaignType {
  CASHBACK = 1,
  RV_BOOSTER,
  GAMIFICATION,
  RANDOM_GAMIFICATION,
}

export enum PromotionCampaignStatus {
  SCHEDULED = 1,
  ONGOING,
  FINISHED,
  FREEZE,
}

export enum PromotionCampaignActiveStatus {
  ACTIVE = 1,
  INACTIVE,
}

export enum PromotionCampaignPriority {
  UP = 1,
  DOWN,
}

export enum PromotionCampaignHistoryStatus {
  RESERVED = 1,
  SUCCESS,
  CANCELED,
  CAMPAIGN_RELASED,
}

export const SortByItems = [
  {
    id: 'latest',
    label: 'Newest',
  },
  {
    id: 'earliest',
    label: 'Oldest',
  },
]

export const UserStatusItems = [
  {
    id: String(Status.ACTIVE),
    label: 'Active',
  },
  {
    id: String(Status.INACTIVE),
    label: 'Inactive',
  },
  {
    id: String(Status.PENDING),
    label: 'Unconfirmed',
  },
]

export const StoreStatusItems = [
  {
    id: String(StoreStatus.ACTIVE),
    label: 'Active',
  },
  {
    id: String(StoreStatus.LOW_ACTIVE),
    label: 'Low Active',
  },
  {
    id: String(StoreStatus.INACTIVE),
    label: 'Inactive',
  },
]

export const StoreStatusArchivedItems = [
  {
    id: String(StoreStatus.CLOSED),
    label: 'Closed',
  },
]

export const PartnerStatusItems = [
  {
    id: String(PartnerStatus.ACTIVE),
    label: 'Active',
  },
  {
    id: String(PartnerStatus.INACTIVE),
    label: 'Not Active',
  },
]

export const PromoterStatusItems = [
  {
    id: String(PromoterStatus.ACTIVE),
    label: 'Active',
  },
  {
    id: String(PromoterStatus.UNCONFIRMED),
    label: 'Unconfirmed',
  },
]

export const PromoterStatusArchivedItems = [
  {
    id: String(PromoterStatus.INACTIVE),
    label: 'Inactive',
  },
]

export const StorePICStatusItems = [
  {
    id: String(StorePICStatus.UNCONFIRMED),
    label: 'Unconfirmed',
  },
  {
    id: String(StorePICStatus.REGISTERED),
    label: 'Registered',
  },
  {
    id: String(StorePICStatus.DRAFT),
    label: 'Draft',
  },
]

export const ExclusivityFlagItems = [
  {
    id: String(ExclusivityFlag.MULTI_BRAND),
    label: 'Multi Brand',
  },
  {
    id: String(ExclusivityFlag.SAMSUNG_EXCLUSIVE),
    label: 'Samsung Exclusive',
  },
]

export const ChannelTypeItems = [
  {
    id: String(ChannelType.MODERN_CHANNEL),
    label: 'Modern Channel',
  },
  {
    id: String(ChannelType.TRADITIONAL),
    label: 'Traditional',
  },
]

export const BrandShopItems = [
  {
    id: String(BrandShop.SAMSUNG_EXPERIENCE_STORE),
    label: 'Samsung Experience Store',
  },
  {
    id: String(BrandShop.SAMSUNG_EXPERIENCE_STORE_PARTNER),
    label: 'Samsung Experience Store Partner',
  },
  {
    id: String(BrandShop.SHOP_IN_SHOP),
    label: 'Shop In Shop',
  },
  {
    id: String(BrandShop.NORMAL_STORE),
    label: 'Normal Store',
  },
  {
    id: String(BrandShop.WINNING_ZONE),
    label: 'Winning Zone',
  },
]

export const PartnerTypeItems = [
  {
    id: String(PartnerType.ALL),
    label: 'All',
  },
  {
    id: String(PartnerType.OFFICIAL),
    label: 'Official',
  },
  {
    id: String(PartnerType.LOCAL),
    label: 'Local',
  },
]

export const PartnerItems = [
  {
    id: String(Partner.ENB),
    label: 'ENB',
  },
  {
    id: String(Partner.LAKU6),
    label: 'Laku6',
  },
  {
    id: String(Partner.COMPASIA),
    label: 'Compasia',
  },
]

export const DeviceCategoryItems = [
  {
    id: String(DeviceCategory.SMARTPHONE_TABLET),
    label: 'Smartphone/Tablet',
  },
  {
    id: String(DeviceCategory.SMARTWATCH),
    label: 'Smartwatch',
  },
  {
    id: String(DeviceCategory.EARBUD),
    label: 'Earbuds',
  },
]

export const DeviceCategoryWearableItems = [
  {
    id: String(DeviceCategory.SMARTWATCH),
    label: 'Smartwatch',
  },
  {
    id: String(DeviceCategory.EARBUD),
    label: 'Earbuds',
  },
]

export const DeviceStatusItems = [
  {
    id: String(DeviceStatus.ACTIVE),
    label: 'Active',
  },
  {
    id: String(DeviceStatus.INACTIVE),
    label: 'Inactive',
  },
]

export const DeviceConnectivityItems = [
  {
    id: String(DeviceConnectivity.WIFI_ONLY),
    label: 'Wifi',
  },
  {
    id: String(DeviceConnectivity.WIFI_CELLULAR),
    label: 'Wifi + Cellular',
  },
  {
    id: String(DeviceConnectivity.NO_CONNECTIVITY),
    label: 'No Connectivity',
  },
]

export const TradeInStatusItems = [
  {
    id: String(TradeInStatus.ACTIVE),
    label: 'Active',
  },
  {
    id: String(TradeInStatus.SUCCESS),
    label: 'Success',
  },
  {
    id: String(TradeInStatus.CANCELED),
    label: 'Canceled',
  },
  {
    id: String(TradeInStatus.EXPIRED),
    label: 'Expired',
  },
  {
    id: String(TradeInStatus.VOID),
    label: 'Void',
  },
  {
    id: String(TradeInStatus.WAITING_PO),
    label: 'Waiting PO',
  },
]

export const TradeInPromotionStatusItems = [
  {
    id: String(TradeInStatus.ACTIVE),
    label: 'Active',
  },
  {
    id: String(TradeInStatus.SUCCESS),
    label: 'Success',
  },
  {
    id: String(TradeInStatus.WAITING_PO),
    label: 'Waiting PO',
  },
]

export const TradeInGradeItems = [
  {
    id: String(TradeInGrade.A),
    label: 'A',
  },
  {
    id: String(TradeInGrade.B),
    label: 'B',
  },
  {
    id: String(TradeInGrade.B2),
    label: 'B2',
  },
  {
    id: String(TradeInGrade.C),
    label: 'C',
  },
  {
    id: String(TradeInGrade.D),
    label: 'D',
  },
]

export const PromotionStatusItems = [
  {
    id: String(PromotionStatus.SCHEDULED),
    label: 'Scheduled',
  },
  {
    id: String(PromotionStatus.ONGOING),
    label: 'On Going',
  },
  {
    id: String(PromotionStatus.FINISHED),
    label: 'Finished',
  },
  {
    id: String(PromotionStatus.FREEZE),
    label: 'Freeze',
  },
]

export const PromotionActiveStatusItems = [
  {
    id: String(PromotionActiveStatus.ACTIVE),
    label: 'Active',
  },
  {
    id: String(PromotionActiveStatus.INACTIVE),
    label: 'Not Active',
  },
]

export const IsPreOrderItems = [
  {
    id: String(OrderType.REGULAR),
    label: 'No',
  },
  {
    id: String(OrderType.PRE_ORDER),
    label: 'Yes',
  },
]

export const OrderStatusItems = [
  {
    id: String(OrderType.PRE_ORDER),
    label: 'Pre-Order',
  },
  {
    id: String(OrderType.REGULAR),
    label: 'Not Pre-Order',
  },
]

export const TransactionTypeItems = [
  {
    id: String(OrderType.PRE_ORDER),
    label: 'Pre-Order',
  },
  {
    id: String(OrderType.REGULAR),
    label: 'Normal',
  },
]

export const TabDetailDevicesItems = [
  {
    id: TabDetailDevices.FIRST_ASSESMENT,
    label: 'First Assessment',
  },
  {
    id: TabDetailDevices.RE_ASSESMENT,
    label: 'Re-Assessment',
  },
]

export const PromotionCampaignGroupItems = [
  {
    id: String(PromotionCampaignType.CASHBACK),
    label: 'Cashback',
  },
  {
    id: String(PromotionCampaignType.RV_BOOSTER),
    label: 'RV Booster',
  },
  {
    id: String(PromotionCampaignType.GAMIFICATION),
    label: 'Reward',
  },
  {
    id: String(PromotionCampaignType.RANDOM_GAMIFICATION),
    label: 'Random Reward',
  },
]

export const PromotionCampaignHistoryStatusItems = [
  {
    id: String(PromotionCampaignHistoryStatus.RESERVED),
    label: 'Reserved',
  },
  {
    id: String(PromotionCampaignHistoryStatus.SUCCESS),
    label: 'Success',
  },
  {
    id: String(PromotionCampaignHistoryStatus.CANCELED),
    label: 'Canceled',
  },
]

export const StoreStatusBadge = (statusId: number) => {
  let variant: any = 'dark'
  let text = 'Unknown'

  switch (statusId) {
    case StoreStatus.ACTIVE:
      variant = 'success'
      text = 'Active'
      break
    case StoreStatus.INACTIVE:
      variant = 'warning'
      text = 'Inactive'
      break
    case StoreStatus.LOW_ACTIVE:
      variant = 'info'
      text = 'Low Active'
      break
    case StoreStatus.CLOSED:
      variant = 'error'
      text = 'Low Active'
      break
  }

  return { variant, text }
}

export const StorePICStatusBadge = (statusId: number) => {
  let variant: any = 'dark'
  let text = 'Unknown'

  switch (statusId) {
    case StorePICStatus.REGISTERED:
      variant = 'success'
      text = 'Registered'
      break
    case StorePICStatus.UNCONFIRMED:
      variant = 'warning'
      text = 'Unconfirmed'
      break
    case StorePICStatus.DRAFT:
      variant = 'dark'
      text = 'Draft'
      break
  }

  return { variant, text }
}

export const PartnerStatusBadge = (statusId: number) => {
  let variant: any = 'dark'
  let text = 'Unknown'

  switch (statusId) {
    case PartnerStatus.ACTIVE:
      variant = 'success'
      text = 'Active'
      break
    case PartnerStatus.INACTIVE:
      variant = 'error'
      text = 'Not Active'
      break
  }

  return { variant, text }
}

export const PromoterStatusBadge = (statusId: number) => {
  let variant: any = 'dark'
  let text = 'Unknown'

  switch (statusId) {
    case PromoterStatus.ACTIVE:
      variant = 'success'
      text = 'Active'
      break
    case PromoterStatus.INACTIVE:
      variant = 'error'
      text = 'Inactive'
      break
    case PromoterStatus.UNCONFIRMED:
      variant = 'warning'
      text = 'Unconfirmed'
      break
  }

  return { variant, text }
}

export const PromotionStatusBadge = (statusId: number) => {
  let variant: any = 'dark'
  let text = 'Unknown'

  switch (statusId) {
    case PromotionStatus.SCHEDULED:
      variant = 'warning'
      text = 'Scheduled'
      break
    case PromotionStatus.ONGOING:
      variant = 'info'
      text = 'On Going'
      break
    case PromotionStatus.FINISHED:
      variant = 'success'
      text = 'Finished'
      break

    case PromotionStatus.FREEZE:
      variant = 'dark'
      text = 'Freeze'
      break
  }

  return { variant, text }
}

export const PromotionActiveStatusBadge = (statusId: number) => {
  let variant: any = 'dark'
  let text = 'Unknown'

  switch (statusId) {
    case PromotionActiveStatus.ACTIVE:
      variant = 'success'
      text = 'Active'
      break
    case PromotionActiveStatus.INACTIVE:
      variant = 'error'
      text = 'Inactive'
      break
  }

  return { variant, text }
}

export const DeviceStatusBadge = (statusId: number) => {
  let variant: any = 'dark'
  let text = 'Unknown'

  switch (statusId) {
    case DeviceStatus.ACTIVE:
      variant = 'success'
      text = 'Active'
      break
    case DeviceStatus.INACTIVE:
      variant = 'error'
      text = 'Inactive'
      break
  }

  return { variant, text }
}

export const TradeInStatusBadge = (statusId: number) => {
  let variant: any = 'dark'
  let text = 'Unknown'

  switch (statusId) {
    case TradeInStatus.ACTIVE:
    case TradeInStatus.CONFIRM:
      variant = 'info'
      text = 'Active'
      break
    case TradeInStatus.SUCCESS:
      variant = 'success'
      text = 'Success'
      break
    case TradeInStatus.VOID:
      variant = 'error'
      text = 'Void'
      break
    case TradeInStatus.CANCELED:
      variant = 'warning'
      text = 'Canceled'
      break
    case TradeInStatus.EXPIRED:
      variant = 'dark'
      text = 'Expired'
      break
    case TradeInStatus.WAITING_PO:
      variant = 'waiting'
      text = 'Waiting PO'
      break
  }

  return { variant, text }
}

export const TradeInPickupStatusBadge = (statusId: number) => {
  let variant: any = 'dark'
  let text = 'Unknown'

  switch (statusId) {
    case TradeInPickupStatus.WAITING:
      variant = 'info'
      text = 'Active'
      break
    case TradeInPickupStatus.DONE:
      variant = 'success'
      text = 'Done'
      break
    case TradeInPickupStatus.CANCELED:
      variant = 'error'
      text = 'Canceled'
      break
  }

  return { variant, text }
}

export const TradeInPaymentStatusBadge = (statusId: number) => {
  let variant: any = 'dark'
  let text = 'Unknown'

  switch (statusId) {
    case TradeInPaymentStatus.WAITING:
      variant = 'info'
      text = 'Active'
      break
    case TradeInPaymentStatus.DONE:
      variant = 'success'
      text = 'Done'
      break
    case TradeInPaymentStatus.CANCELED:
      variant = 'error'
      text = 'Canceled'
      break
  }

  return { variant, text }
}

export const TradeInValidationStatusBadge = (statusId: number) => {
  let variant: any = 'dark'
  let text = 'Unknown'

  switch (statusId) {
    case TradeInValidationStatus.VALIDATED:
      variant = 'success'
      text = 'Validated'
      break
    case TradeInValidationStatus.UPDATE_REQUIRED:
      variant = 'warning'
      text = 'Update Required'
      break
    case TradeInValidationStatus.WAITING_VALIDATION:
      variant = 'info'
      text = 'Waiting Validation'
      break
    case TradeInValidationStatus.REJECTED:
      variant = 'error'
      text = 'Rejected'
      break
    case TradeInValidationStatus.RECHECK:
      variant = 'dark'
      text = 'Recheck'
      break
  }

  return { variant, text }
}

export const TradeInDevicePriceOfferTimeBadge = (timeout: number) => {
  let variant: any = 'dark'
  let text = 'Unknown'

  switch (timeout) {
    case 0:
      variant = 'success'
      text = 'On Time'
      break
    default:
      variant = 'error'
      text = 'Time Out'
  }

  return { variant, text }
}

export const TradeInPhotoLabel = (typeId: number) => {
  switch (typeId) {
    case TradeInPhoto.FRONT:
      return 'Front Photo'
    case TradeInPhoto.BACK:
      return 'Back Photo'
    case TradeInPhoto.LEFT:
      return 'Left Photo'
    case TradeInPhoto.RIGHT:
      return 'Right Photo'
    case TradeInPhoto.TOP:
      return 'Top Photo'
    case TradeInPhoto.BOTTOM:
      return 'Bottom Photo'
    case TradeInPhoto.OTHERS:
      return 'Others Photo'
    case TradeInPhoto.ENB_FRONT:
      return 'Front ENB Photo'
    case TradeInPhoto.ENB_OTHER:
      return 'Others ENB Photo'
    default:
      return 'Unknown Photo'
  }
}

export const VendorContributionStatusLabel = (statusId: number) => {
  switch (statusId) {
    case VendorContributionStatus.ACTIVE:
      return 'Success'
    case VendorContributionStatus.INACTIVE:
    default:
      return 'Offer Not Shown'
  }
}

export const OrderTypeLabel = (typeId: number) => {
  switch (typeId) {
    case OrderType.REGULAR:
      return 'Not Pre-Order'
    case OrderType.PRE_ORDER:
      return 'Pre-Order'
  }
}

export const PartnerTypeLabel = (id: number) => {
  switch (id) {
    case Partner.ENB:
      return 'ENB'
    case Partner.LAKU6:
      return 'Laku6'
    case Partner.COMPASIA:
      return 'Compasia'
    default:
      return '-'
  }
}

export const PromotionCampaignGroupLabel = (typeId: number) => {
  switch (typeId) {
    case PromotionCampaignType.CASHBACK:
      return 'Cashback'
    case PromotionCampaignType.RV_BOOSTER:
      return 'RV Booster'
    case PromotionCampaignType.GAMIFICATION:
      return 'Reward'
    case PromotionCampaignType.RANDOM_GAMIFICATION:
      return 'Random Reward'
    default:
      return '-'
  }
}

export const PromotionBannerStatusBadge = (status: string) => {
  let variant: any = 'dark'
  let text = 'Unknown'

  switch (status) {
    case PromotionBannerStatus.SCHEDULED:
      variant = 'warning'
      text = 'Scheduled'
      break
    case PromotionBannerStatus.ONGOING:
      variant = 'info'
      text = 'On Going'
      break
    case PromotionBannerStatus.FINISHED:
      variant = 'success'
      text = 'Finished'
      break
  }

  return { variant, text }
}

export const PromotionCampaignHistoryStatusBadge = (statusId: number) => {
  let variant: 'success' | 'error' | 'warning' | 'dark' = 'dark'
  let text = 'Unknown'

  switch (statusId) {
    case PromotionCampaignHistoryStatus.RESERVED:
      variant = 'warning'
      text = 'Reserved'
      break
    case PromotionCampaignHistoryStatus.SUCCESS:
      variant = 'success'
      text = 'Success'
      break
    case PromotionCampaignHistoryStatus.CANCELED:
    case PromotionCampaignHistoryStatus.CAMPAIGN_RELASED:
      variant = 'error'
      text = 'Canceled'
      break
  }

  return { variant, text }
}

export const PromotionCampaignHistoryLabel = (statusId: number) => {
  switch (statusId) {
    case PromotionCampaignHistoryStatus.RESERVED:
      return 'Reserved'
    case PromotionCampaignHistoryStatus.SUCCESS:
      return 'Success'
    case PromotionCampaignHistoryStatus.CANCELED:
    case PromotionCampaignHistoryStatus.CAMPAIGN_RELASED:
      return 'Canceled'
    default:
      return '-'
  }
}
