import { createApi } from '@reduxjs/toolkit/query/react'

import { LIMIT_EXPORT, TradeInStatus } from '@/config/constants'
import {
  ADMIN_TRADE_IN,
  ADMIN_TRADE_IN_CANCEL,
  ADMIN_TRADE_IN_COUNT,
  ADMIN_TRADE_IN_DETAIL,
  ADMIN_TRADE_IN_DETAIL_ASSESSMENT,
  ADMIN_TRADE_IN_DETAIL_ITEM,
  ADMIN_TRADE_IN_DETAIL_ITEM_PRE_ORDER,
  ADMIN_TRADE_IN_DETAIL_ITEM_PRICE,
  ADMIN_TRADE_IN_DETAIL_PRE_ORDER_SEND_EMAIL,
  ADMIN_TRADE_IN_EXPORT,
} from '@/config/endpoints'
import { BaseDetailRequest } from '@/types/common'
import {
  TradeInCancelRequest,
  TradeInCountResponse,
  TradeInDetailItemRequest,
  TradeInDetailItemResponse,
  TradeInDetailResponse,
  TradeInExportRequest,
  TradeInExportResponse,
  TradeInListAssessmentPriceRequest,
  TradeInListAssessmentPriceResponse,
  TradeInListAssessmentRequest,
  TradeInListAssessmentResponse,
  TradeInListRequest,
  TradeInListResponse,
} from '@/types/trade-in'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'tradeIn',
  tagTypes: ['TradeIn'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListTradeIn: builder.query<TradeInListResponse, TradeInListRequest>({
      query: (request) => {
        const newRequest = { ...request }

        if (request.query && request.query.filters && request.query.filters.statusId) {
          const statusIds = request.query.filters.statusId.split(',').map((statusId) => parseInt(statusId))

          if (statusIds.includes(TradeInStatus.ACTIVE)) {
            statusIds.push(TradeInStatus.CONFIRM)
          }

          newRequest.query = {
            ...request.query,
            filters: {
              ...request.query.filters,
              statusId: statusIds.join(','),
            },
          }
        }

        return {
          ...composeRequest(newRequest, ADMIN_TRADE_IN),
          method: 'GET',
        }
      },
      providesTags: ['TradeIn'],
    }),
    getDetailTradeIn: builder.query<TradeInDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_TRADE_IN_DETAIL),
        method: 'GET',
      }),
    }),
    getDetailItemTradeIn: builder.query<TradeInDetailItemResponse, TradeInDetailItemRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_TRADE_IN_DETAIL_ITEM),
        method: 'GET',
      }),
    }),
    getDetailItemTradeInPreOrder: builder.query<TradeInDetailItemResponse, TradeInDetailItemRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_TRADE_IN_DETAIL_ITEM_PRE_ORDER),
        method: 'GET',
      }),
    }),
    exportListTradeIn: builder.query<TradeInExportResponse, TradeInExportRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: TradeInExportResponse = {
          success: true,
          code: 'OK',
          message: '',
          data: {
            items: [],
            metadata: {
              skip: '0',
              limit: '0',
              sortBy: 'latest',
              count: '0',
            },
          },
        }

        const newRequest = { ...request }

        if (request.query && request.query.filters && request.query.filters.statusId) {
          const statusIds = request.query.filters.statusId.split(',').map((statusId) => parseInt(statusId))

          if (statusIds.includes(TradeInStatus.ACTIVE)) {
            statusIds.push(TradeInStatus.CONFIRM)
          }

          newRequest.query = {
            ...request.query,
            filters: {
              ...request.query.filters,
              statusId: statusIds.join(','),
            },
          }
        }

        const limit = LIMIT_EXPORT
        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit,
                    skip,
                  },
                },
                ADMIN_TRADE_IN_EXPORT
              ),
              method: 'GET',
            })

            const {
              data: { items },
            } = response.data as TradeInExportResponse

            items.forEach((item) => {
              data.data.items.push(item)
            })

            skip += limit
            hasMore = items.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
    cancelTradeIn: builder.mutation<void, TradeInCancelRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_TRADE_IN_CANCEL),
        method: 'PUT',
      }),
      invalidatesTags: ['TradeIn'],
    }),
    getListTradeInAssessment: builder.query<TradeInListAssessmentResponse, TradeInListAssessmentRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_TRADE_IN_DETAIL_ASSESSMENT),
        method: 'GET',
      }),
      providesTags: [],
    }),
    getListTradeInAssessmentPrice: builder.query<TradeInListAssessmentPriceResponse, TradeInListAssessmentPriceRequest>(
      {
        query: (request) => ({
          ...composeRequest(request, ADMIN_TRADE_IN_DETAIL_ITEM_PRICE),
          method: 'GET',
        }),
        providesTags: [],
      }
    ),
    sendEmailPreOrder: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_TRADE_IN_DETAIL_PRE_ORDER_SEND_EMAIL),
        method: 'POST',
      }),
    }),
    countTradeIn: builder.query<TradeInCountResponse, TradeInListRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_TRADE_IN_COUNT),
        method: 'GET',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetListTradeInQuery,
  useLazyGetDetailTradeInQuery,
  useLazyGetDetailItemTradeInQuery,
  useLazyGetDetailItemTradeInPreOrderQuery,
  useLazyExportListTradeInQuery,
  useCancelTradeInMutation,
  useLazyGetListTradeInAssessmentQuery,
  useLazyGetListTradeInAssessmentPriceQuery,
  useSendEmailPreOrderMutation,
  useLazyCountTradeInQuery,
  util: tradeInUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
