import { createApi } from '@reduxjs/toolkit/query/react'

import { LIMIT_EXPORT, PartnerType } from '@/config/constants'
import {
  ADMIN_DASHBOARD_ACTIVE_STORE,
  ADMIN_DASHBOARD_ACTIVE_STORE_EXPORT,
  ADMIN_DASHBOARD_GRADE_RESULT,
  ADMIN_DASHBOARD_GRADE_RESULT_EXPORT,
  ADMIN_DASHBOARD_NUMBER_OF_DEVICE,
  ADMIN_DASHBOARD_NUMBER_OF_DEVICE_EXPORT,
  ADMIN_DASHBOARD_PROMOTER_ACTIVITY,
  ADMIN_DASHBOARD_PROMOTER_ACTIVITY_EXPORT,
  ADMIN_DASHBOARD_PROMOTER_INACTIVE_EXPORT,
  ADMIN_DASHBOARD_PROMOTER_NOT_LOGIN_EXPORT,
  ADMIN_DASHBOARD_PROMOTER_TRANSACTION,
  ADMIN_DASHBOARD_PROMOTER_TRANSACTION_EXPORT,
  ADMIN_DASHBOARD_TRADE_IN,
  ADMIN_DASHBOARD_TRANSACTION_OVERVIEW,
  ADMIN_DASHBOARD_TRANSACTION_OVERVIEW_EXPORT,
  ADMIN_DASHBOARD_VENDOR_CONTRIBUTION,
  ADMIN_DASHBOARD_VENDOR_CONTRIBUTION_EXPORT,
} from '@/config/endpoints'
import { BaseRequest } from '@/types/common'
import {
  DashboardActiveStoreExportRequest,
  DashboardActiveStoreExportResponse,
  DashboardActiveStoreRequest,
  DashboardActiveStoreResponse,
  DashboardExportResponse,
  DashboardGradeResultExportRequest,
  DashboardGradeResultExportResponse,
  DashboardGradeResultRequest,
  DashboardGradeResultResponse,
  DashboardNumberOfDeviceExportRequest,
  DashboardNumberOfDeviceExportResponse,
  DashboardNumberOfDeviceRequest,
  DashboardNumberOfDeviceResponse,
  DashboardPromoterExportRequest,
  DashboardPromoterExportResponse,
  DashboardPromoterInactiveExportResponse,
  DashboardPromoterRequest,
  DashboardPromoterResponse,
  DashboardQueryFiltersRequest,
  DashboardTradeInRequest,
  DashboardTradeInResponse,
  DashboardTransactionOverviewExportRequest,
  DashboardTransactionOverviewExportResponse,
  DashboardTransactionOverviewRequest,
  DashboardTransactionOverviewResponse,
  DashboardVendorContributionExportRequest,
  DashboardVendorContributionExportResponse,
  DashboardVendorContributionRequest,
  DashboardVendorContributionResponse,
} from '@/types/dashboard'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'dashboard',
  tagTypes: ['Dashboard'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    exportAll: builder.query<DashboardExportResponse, BaseRequest<unknown, DashboardQueryFiltersRequest>>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const response: DashboardExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: {
            transactionOverview: [],
            numberOfDevice: [],
            gradeResult: [],
            vendorContribution: [],
            promoterTransaction: [],
            activeStore: [],
            promoterActivity: [],
          },
        }

        const newRequest = { ...request }

        // transaction overview
        let skipTransactionOverview = 0
        let hasMoreTransactionOverview = true

        while (hasMoreTransactionOverview) {
          try {
            const { data: transactionOverviewData } = await baseQuery({
              ...composeRequest(
                { query: { ...newRequest.query, limit: LIMIT_EXPORT, skip: skipTransactionOverview } },
                ADMIN_DASHBOARD_TRANSACTION_OVERVIEW_EXPORT
              ),
              method: 'GET',
            })

            if (transactionOverviewData) {
              const transactionOverviewResponse = transactionOverviewData as DashboardTransactionOverviewExportResponse

              transactionOverviewResponse.data.map((item) => response.data.transactionOverview.push(item))

              skipTransactionOverview += LIMIT_EXPORT
              hasMoreTransactionOverview = transactionOverviewResponse.data.length > 0
            } else {
              hasMoreTransactionOverview = false
            }
          } catch (e) {
            console.error(e)
          }
        }

        // number of device
        let skipNumberOfDevice = 0
        let hasMoreNumberOfDevice = true

        while (hasMoreNumberOfDevice) {
          try {
            const { data: numberOfDeviceData } = await baseQuery({
              ...composeRequest(
                { query: { ...newRequest.query, limit: LIMIT_EXPORT, skip: skipNumberOfDevice } },
                ADMIN_DASHBOARD_NUMBER_OF_DEVICE_EXPORT
              ),
              method: 'GET',
            })

            if (numberOfDeviceData) {
              const numberOfDeviceResponse = numberOfDeviceData as DashboardTransactionOverviewExportResponse

              numberOfDeviceResponse.data.map((item) => response.data.numberOfDevice.push(item))

              skipNumberOfDevice += LIMIT_EXPORT
              hasMoreNumberOfDevice = numberOfDeviceResponse.data.length > 0
            } else {
              hasMoreNumberOfDevice = false
            }
          } catch (e) {
            console.error(e)
          }
        }

        // grade result
        let skipGradeResult = 0
        let hasMoreGradeResult = true

        while (hasMoreGradeResult) {
          try {
            const { data: gradeResultData } = await baseQuery({
              ...composeRequest(
                { query: { ...newRequest.query, limit: LIMIT_EXPORT, skip: skipGradeResult } },
                ADMIN_DASHBOARD_GRADE_RESULT_EXPORT
              ),
              method: 'GET',
            })

            if (gradeResultData) {
              const gradeResultResponse = gradeResultData as DashboardTransactionOverviewExportResponse

              gradeResultResponse.data.map((item) => response.data.gradeResult.push(item))

              skipGradeResult += LIMIT_EXPORT
              hasMoreGradeResult = gradeResultResponse.data.length > 0
            } else {
              hasMoreGradeResult = false
            }
          } catch (e) {
            console.error(e)
          }
        }

        // vendor contribution
        let skipVendorContribution = 0
        let hasMoreVendorContribution = true

        while (hasMoreVendorContribution) {
          try {
            const { data: vendorContributionData } = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip: skipVendorContribution,
                  },
                },
                ADMIN_DASHBOARD_VENDOR_CONTRIBUTION_EXPORT
              ),
              method: 'GET',
            })

            if (vendorContributionData) {
              const vendorContributionResponse = vendorContributionData as DashboardVendorContributionExportResponse

              vendorContributionResponse.data.map((item) => response.data.vendorContribution.push(item))

              skipVendorContribution += LIMIT_EXPORT
              hasMoreVendorContribution = vendorContributionResponse.data.length > 0
            } else {
              hasMoreVendorContribution = false
            }
          } catch (e) {
            console.error(e)
          }
        }

        // promoter transaction
        let skipPromoterTransaction = 0
        let hasMorePromoterTransaction = true

        while (hasMorePromoterTransaction) {
          try {
            const { data: promoterTransactionData } = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip: skipPromoterTransaction,
                  },
                },
                ADMIN_DASHBOARD_PROMOTER_TRANSACTION_EXPORT
              ),
              method: 'GET',
            })

            if (promoterTransactionData) {
              const promoterTransactionResponse = promoterTransactionData as DashboardPromoterExportResponse

              promoterTransactionResponse.data.map((item) => response.data.promoterTransaction.push(item))

              skipPromoterTransaction += LIMIT_EXPORT
              hasMorePromoterTransaction = promoterTransactionResponse.data.length > 0
            } else {
              hasMorePromoterTransaction = false
            }
          } catch (e) {
            console.error(e)
          }
        }

        let skipPromoterInactive = 0
        let hasMorePromoterInactive = true

        while (hasMorePromoterInactive) {
          try {
            const { data: promoterInactiveData } = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip: skipPromoterInactive,
                  },
                },
                ADMIN_DASHBOARD_PROMOTER_INACTIVE_EXPORT
              ),
              method: 'GET',
            })

            if (promoterInactiveData) {
              const promoterInactiveResponse = promoterInactiveData as DashboardPromoterInactiveExportResponse

              promoterInactiveResponse.data.map((item) =>
                response.data.promoterTransaction.push({
                  ...item,
                  status: item.promoterStatus,
                })
              )

              skipPromoterInactive += LIMIT_EXPORT
              hasMorePromoterInactive = promoterInactiveResponse.data.length > 0
            } else {
              hasMorePromoterInactive = false
            }
          } catch (e) {
            console.error(e)
          }
        }

        // active store
        let skipActiveStore = 0
        let hasMoreActiveStore = true

        if (request.query?.filters?.partnerTypeId === String(PartnerType.LOCAL)) {
          hasMoreActiveStore = false
        }

        while (hasMoreActiveStore) {
          try {
            const { data: activeStoreData } = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...request.query,
                    filters: {
                      ...request.query?.filters,
                      partnerTypeId: undefined,
                    },
                    limit: LIMIT_EXPORT,
                    skip: skipActiveStore,
                  },
                },
                ADMIN_DASHBOARD_ACTIVE_STORE_EXPORT
              ),
              method: 'GET',
            })

            if (activeStoreData) {
              const activeStoreResponse = activeStoreData as DashboardActiveStoreExportResponse

              activeStoreResponse.data.map((item) => response.data.activeStore.push(item))

              skipActiveStore += LIMIT_EXPORT
              hasMoreActiveStore = activeStoreResponse.data.length > 0
            } else {
              hasMoreActiveStore = false
            }
          } catch (e) {
            console.error(e)
          }
        }

        // promoter activity
        let skipPromoterActivity = 0
        let hasMorePromoterActivity = true

        let skipPromoterNotLogin = 0
        let hasMorePromoterNotLogin = true

        if (request.query?.filters?.partnerTypeId === String(PartnerType.LOCAL)) {
          hasMorePromoterActivity = false
          hasMorePromoterNotLogin = false
        } else {
          while (hasMorePromoterActivity) {
            try {
              const { data: promoterActivityData } = await baseQuery({
                ...composeRequest(
                  {
                    query: {
                      ...newRequest.query,
                      limit: LIMIT_EXPORT,
                      skip: skipPromoterActivity,
                    },
                  },
                  ADMIN_DASHBOARD_PROMOTER_ACTIVITY_EXPORT
                ),
                method: 'GET',
              })

              if (promoterActivityData) {
                const promoterActivityResponse = promoterActivityData as DashboardPromoterExportResponse

                promoterActivityResponse.data.map((item) => response.data.promoterActivity.push(item))

                skipPromoterActivity += LIMIT_EXPORT
                hasMorePromoterActivity = promoterActivityResponse.data.length > 0
              } else {
                hasMorePromoterActivity = false
              }
            } catch (e) {
              console.error(e)
            }
          }

          while (hasMorePromoterNotLogin) {
            try {
              const { data: promoterNotLoginData } = await baseQuery({
                ...composeRequest(
                  {
                    query: {
                      ...newRequest.query,
                      limit: LIMIT_EXPORT,
                      skip: skipPromoterNotLogin,
                    },
                  },
                  ADMIN_DASHBOARD_PROMOTER_NOT_LOGIN_EXPORT
                ),
                method: 'GET',
              })

              if (promoterNotLoginData) {
                const promoterNotLoginResponse = promoterNotLoginData as DashboardPromoterInactiveExportResponse

                promoterNotLoginResponse.data.map((item) =>
                  response.data.promoterActivity.push({
                    ...item,
                    status: item.promoterStatus,
                  })
                )

                skipPromoterNotLogin += LIMIT_EXPORT
                hasMorePromoterNotLogin = promoterNotLoginResponse.data.length > 0
              } else {
                hasMorePromoterNotLogin = false
              }
            } catch (e) {
              console.error(e)
            }
          }
        }

        return { data: response }
      },
    }),
    tradeIn: builder.query<DashboardTradeInResponse, DashboardTradeInRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_DASHBOARD_TRADE_IN),
        method: 'GET',
      }),
    }),
    transactionOverview: builder.query<DashboardTransactionOverviewResponse, DashboardTransactionOverviewRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: DashboardTransactionOverviewResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: {
            items: [],
            hasMore: false,
          },
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip,
                  },
                },
                ADMIN_DASHBOARD_TRANSACTION_OVERVIEW
              ),
              method: 'GET',
            })

            if (response.data) {
              const { data: transactionOverview } = response.data as DashboardTransactionOverviewResponse

              transactionOverview.items.map((item) => data.data.items.push(item))

              skip += LIMIT_EXPORT
              hasMore = transactionOverview.hasMore
            } else {
              hasMore = false
            }
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
    transactionOverviewExport: builder.query<
      DashboardTransactionOverviewExportResponse,
      DashboardTransactionOverviewExportRequest
    >({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const data: DashboardTransactionOverviewExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                { query: { ...newRequest.query, limit: LIMIT_EXPORT, skip } },
                ADMIN_DASHBOARD_TRANSACTION_OVERVIEW_EXPORT
              ),
              method: 'GET',
            })

            if (response.data) {
              const { data: transactionOverview } = response.data as DashboardTransactionOverviewExportResponse

              transactionOverview.map((item) => data.data.push(item))

              skip += LIMIT_EXPORT
              hasMore = transactionOverview.length > 0
            } else {
              hasMore = false
            }
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
    numberOfDevice: builder.query<DashboardNumberOfDeviceResponse, DashboardNumberOfDeviceRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: DashboardNumberOfDeviceResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: {
            items: [],
            hasMore: false,
          },
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip,
                  },
                },
                ADMIN_DASHBOARD_NUMBER_OF_DEVICE
              ),
              method: 'GET',
            })

            if (response.data) {
              const { data: numberOfDevice } = response.data as DashboardNumberOfDeviceResponse

              numberOfDevice.items.map((item) => data.data.items.push(item))

              skip += LIMIT_EXPORT
              hasMore = numberOfDevice.hasMore
            } else {
              hasMore = false
            }
          } catch (error) {
            console.error(error)
          }
        }

        return { data }
      },
    }),
    numberOfDeviceExport: builder.query<DashboardNumberOfDeviceExportResponse, DashboardNumberOfDeviceExportRequest>({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const data: DashboardNumberOfDeviceExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                { query: { ...newRequest.query, limit: LIMIT_EXPORT, skip } },
                ADMIN_DASHBOARD_NUMBER_OF_DEVICE_EXPORT
              ),
              method: 'GET',
            })

            if (response.data) {
              const { data: numberOfDevice } = response.data as DashboardNumberOfDeviceExportResponse

              numberOfDevice.map((item) => data.data.push(item))

              skip += LIMIT_EXPORT
              hasMore = numberOfDevice.length > 0
            } else {
              hasMore = false
            }
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
    gradeResult: builder.query<DashboardGradeResultResponse, DashboardGradeResultRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: DashboardGradeResultResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: {
            items: [],
            hasMore: false,
          },
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip,
                  },
                },
                ADMIN_DASHBOARD_GRADE_RESULT
              ),
              method: 'GET',
            })

            if (response.data) {
              const { data: gradeResult } = response.data as DashboardGradeResultResponse

              gradeResult.items.map((item) => data.data.items.push(item))

              skip += LIMIT_EXPORT
              hasMore = gradeResult.hasMore
            } else {
              hasMore = false
            }
          } catch (error) {
            console.error(error)
          }
        }

        return { data }
      },
    }),
    gradeResultExport: builder.query<DashboardGradeResultExportResponse, DashboardGradeResultExportRequest>({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const data: DashboardGradeResultExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                { query: { ...newRequest.query, limit: LIMIT_EXPORT, skip } },
                ADMIN_DASHBOARD_GRADE_RESULT_EXPORT
              ),
              method: 'GET',
            })

            if (response.data) {
              const { data: gradeResult } = response.data as DashboardGradeResultExportResponse

              gradeResult.map((item) => data.data.push(item))

              skip += LIMIT_EXPORT
              hasMore = gradeResult.length > 0
            } else {
              hasMore = false
            }
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
    vendorContribution: builder.query<DashboardVendorContributionResponse, DashboardVendorContributionRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: DashboardVendorContributionResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: {
            items: [],
            hasMore: false,
          },
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip,
                  },
                },
                ADMIN_DASHBOARD_VENDOR_CONTRIBUTION
              ),
              method: 'GET',
            })

            if (response.data) {
              const { data: vendorContribution } = response.data as DashboardVendorContributionResponse

              vendorContribution.items.map((item) => data.data.items.push(item))

              skip += LIMIT_EXPORT
              hasMore = vendorContribution.hasMore
            } else {
              hasMore = false
            }
          } catch (error) {
            console.error(error)
          }
        }

        return { data }
      },
    }),
    vendorContributionExport: builder.query<
      DashboardVendorContributionExportResponse,
      DashboardVendorContributionExportRequest
    >({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const response: DashboardVendorContributionExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const responseVendorContribution = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip,
                  },
                },
                ADMIN_DASHBOARD_VENDOR_CONTRIBUTION_EXPORT
              ),
              method: 'GET',
            })

            if (responseVendorContribution.data) {
              const { data: vendorContributionData } =
                responseVendorContribution.data as DashboardVendorContributionExportResponse

              vendorContributionData.map((item) => response.data.push(item))

              skip += LIMIT_EXPORT
              hasMore = vendorContributionData.length > 0
            } else {
              hasMore = false
            }
          } catch (error) {
            console.error(error)
          }
        }

        return { data: response }
      },
    }),
    promoterTransaction: builder.query<DashboardPromoterResponse, DashboardPromoterRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: DashboardPromoterResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: {
            items: [],
            hasMore: false,
          },
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip,
                  },
                },
                ADMIN_DASHBOARD_PROMOTER_TRANSACTION
              ),
              method: 'GET',
            })

            if (response.data) {
              const { data: promoterTransaction } = response.data as DashboardPromoterResponse

              promoterTransaction.items.map((item) => data.data.items.push(item))

              skip += LIMIT_EXPORT
              hasMore = promoterTransaction.hasMore
            } else {
              hasMore = false
            }
          } catch (error) {
            console.error(error)
          }
        }

        return { data }
      },
    }),
    promoterTransactionExport: builder.query<DashboardPromoterExportResponse, DashboardPromoterExportRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const response: DashboardPromoterExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        const newRequest = { ...request }

        let skipPromoter = 0
        let hasMorePromoter = true

        while (hasMorePromoter) {
          try {
            const responsePromoterTransaction = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip: skipPromoter,
                  },
                },
                ADMIN_DASHBOARD_PROMOTER_TRANSACTION_EXPORT
              ),
              method: 'GET',
            })

            if (responsePromoterTransaction.data) {
              const { data: promoterTransactionData } =
                responsePromoterTransaction.data as DashboardPromoterExportResponse

              promoterTransactionData.map((item) => response.data.push(item))

              skipPromoter += LIMIT_EXPORT
              hasMorePromoter = promoterTransactionData.length > 0
            } else {
              hasMorePromoter = false
            }
          } catch (error) {
            console.error(error)
          }
        }

        let skipInactive = 0
        let hasMoreInactive = true

        while (hasMoreInactive) {
          try {
            const responsePromoterInactive = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip: skipInactive,
                  },
                },
                ADMIN_DASHBOARD_PROMOTER_INACTIVE_EXPORT
              ),
              method: 'GET',
            })

            if (responsePromoterInactive.data) {
              const { data: responsePromoterInactiveData } =
                responsePromoterInactive.data as DashboardPromoterInactiveExportResponse

              responsePromoterInactiveData.map((item) => {
                response.data.push({
                  ...item,
                  status: item.promoterStatus,
                })
              })

              skipInactive += LIMIT_EXPORT
              hasMoreInactive = responsePromoterInactiveData.length > 0
            } else {
              hasMoreInactive = false
            }
          } catch (error) {
            console.error(error)
          }
        }

        return { data: response }
      },
    }),
    activeStore: builder.query<DashboardActiveStoreResponse, DashboardActiveStoreRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: DashboardActiveStoreResponse = {
          success: true,
          code: 'INTERNAL',
          message: '',
          data: {
            items: [],
            hasMore: false,
          },
        }

        if (request.query?.filters?.partnerTypeId === String(PartnerType.LOCAL)) {
          return { data }
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip,
                    filters: {
                      ...newRequest.query?.filters,
                      partnerTypeId: undefined,
                    },
                  },
                },
                ADMIN_DASHBOARD_ACTIVE_STORE
              ),
              method: 'GET',
            })

            if (response.data) {
              const { data: activeStore } = response.data as DashboardActiveStoreResponse

              activeStore.items.map((item) => data.data.items.push(item))

              skip += LIMIT_EXPORT
              hasMore = activeStore.hasMore
            } else {
              hasMore = false
            }
          } catch (error) {
            console.error(error)
          }
        }

        return { data }
      },
    }),
    activeStoreExport: builder.query<DashboardActiveStoreExportResponse, DashboardActiveStoreExportRequest>({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const data: DashboardActiveStoreExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        if (request.query?.filters?.partnerTypeId === String(PartnerType.LOCAL)) {
          return { data }
        }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...request.query,
                    filters: {
                      ...request.query?.filters,
                      partnerTypeId: undefined,
                    },
                    limit: LIMIT_EXPORT,
                    skip,
                  },
                },
                ADMIN_DASHBOARD_ACTIVE_STORE_EXPORT
              ),
              method: 'GET',
            })

            if (response.data) {
              const { data: activeStore } = response.data as DashboardActiveStoreExportResponse

              activeStore.map((item) => data.data.push(item))

              skip += LIMIT_EXPORT
              hasMore = activeStore.length > 0
            } else {
              hasMore = false
            }
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
    promoterActivity: builder.query<DashboardPromoterResponse, DashboardPromoterRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: DashboardPromoterResponse = {
          success: true,
          code: 'INTERNAL',
          message: '',
          data: {
            items: [],
            hasMore: false,
          },
        }

        if (request.query?.filters?.partnerTypeId === String(PartnerType.LOCAL)) {
          return { data }
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip,
                    filters: {
                      ...newRequest.query?.filters,
                      partnerTypeId: undefined,
                    },
                  },
                },
                ADMIN_DASHBOARD_PROMOTER_ACTIVITY
              ),
              method: 'GET',
            })

            if (response.data) {
              const { data: promoterActivity } = response.data as DashboardPromoterResponse

              promoterActivity.items.map((item) => data.data.items.push(item))

              skip += LIMIT_EXPORT
              hasMore = promoterActivity.hasMore
            } else {
              hasMore = false
            }
          } catch (error) {
            console.error(error)
          }
        }

        return { data }
      },
    }),
    promoterActivityExport: builder.query<DashboardPromoterExportResponse, DashboardPromoterExportRequest>({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const response: DashboardPromoterExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        if (request.query?.filters?.partnerTypeId === String(PartnerType.LOCAL)) {
          return { data: response }
        }

        const newRequest = { ...request }

        if (request.query && request.query.filters && request.query.filters.partnerTypeId) {
          newRequest.query = {
            ...request.query,
            filters: {
              ...request.query.filters,
              partnerTypeId: undefined,
            },
          }
        }

        let skipPromoterActivity = 0
        let hasMorePromoterActivity = true

        while (hasMorePromoterActivity) {
          try {
            const responsePromoterActivity = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip: skipPromoterActivity,
                  },
                },
                ADMIN_DASHBOARD_PROMOTER_ACTIVITY_EXPORT
              ),
              method: 'GET',
            })

            if (responsePromoterActivity.data) {
              const { data: promoterActivityData } = responsePromoterActivity.data as DashboardPromoterExportResponse

              promoterActivityData.map((item) => response.data.push(item))

              skipPromoterActivity += LIMIT_EXPORT
              hasMorePromoterActivity = promoterActivityData.length > 0
            } else {
              hasMorePromoterActivity = false
            }
          } catch (error) {
            console.error(error)
          }
        }

        let skipPromoterNotLogin = 0
        let hasMorePromoterNotLogin = true

        while (hasMorePromoterNotLogin) {
          try {
            const responsePromoterNotLogin = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit: LIMIT_EXPORT,
                    skip: skipPromoterNotLogin,
                  },
                },
                ADMIN_DASHBOARD_PROMOTER_NOT_LOGIN_EXPORT
              ),
              method: 'GET',
            })

            if (responsePromoterNotLogin.data) {
              const { data: promoterNotLoginData } =
                responsePromoterNotLogin.data as DashboardPromoterInactiveExportResponse

              promoterNotLoginData.map((item) =>
                response.data.push({
                  ...item,
                  status: item.promoterStatus,
                })
              )

              skipPromoterNotLogin += LIMIT_EXPORT
              hasMorePromoterNotLogin = promoterNotLoginData.length > 0
            } else {
              hasMorePromoterNotLogin = false
            }
          } catch (error) {
            console.error(error)
          }
        }

        return { data: response }
      },
    }),
  }),
})

// Export hooks for usage in functional components
export const { util: dashboardUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
